<template>
  <div class="modal-overlay" @click="$emit('close-modal')"></div>
  <!-- Image View Modal -->
  <div
    v-if="viewImages"
    :style="{ 'background-image': backgroundImage }"
    style="background-size: cover"
    class="modal relative"
  >
    <!-- RIght Arrow -->
    <span
      v-if="currentImageIndex + 1 < existingFiles.length"
      @click="currentImageIndex++"
      style="right: 0; top: 50%; transform: translate(50%, -50%)"
      class="
        cursor-pointer
        absolute
        w-10
        h-10
        bg-black
        rounded-full
        flex
        justify-center
        items-center
        opacity-60
      "
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#ffffff"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M9 18l6-6-6-6" /></svg
    ></span>
    <!-- / RIght Arrow -->
    <!-- Left Arrow -->
    <span
      v-if="currentImageIndex - 1 >= 0"
      @click="currentImageIndex--"
      style="left: 0; top: 50%; transform: translate(-50%, -50%)"
      class="
        cursor-pointer
        absolute
        w-10
        h-10
        bg-black
        rounded-full
        flex
        justify-center
        items-center
        opacity-60
      "
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#ffffff"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M15 18l-6-6 6-6" /></svg
    ></span>
    <!-- / Left Arrow -->
    <div class="close flex">
      <span
        class="mr-2"
        @click="$emit('removeExistingFile', currentImageIndex, index)"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#ffffff"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <polyline points="3 6 5 6 21 6"></polyline>
          <path
            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
          ></path>
          <line x1="10" y1="11" x2="10" y2="17"></line>
          <line x1="14" y1="11" x2="14" y2="17"></line></svg
      ></span>
      <span class="mr-2" @click="viewImages = false">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"
          ></path>
          <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
        </svg>
      </span>

      <span @click="$emit('close-modal')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="15" y1="9" x2="9" y2="15"></line>
          <line x1="9" y1="9" x2="15" y2="15"></line>
        </svg>
      </span>
    </div>
  </div>
  <!-- / Image View Modal -->
  <!-- Image upload Modal -->
  <div
    v-if="!viewImages"
    style="overflow: hidden"
    class="modal flex flex-col"
    @click.stop
  >
    <div class="modal-content">
      <img class="check" src=" " alt="" />
      <h6>Select Images</h6>
      <div
        ref="dragDrop"
        class="
          items-center
          text-center
          border
          rounded
          border-gray-400
          hover:border-black
          border-dashed
          p-10
          px-20
        "
        @dragover.prevent="handleDragOver"
        @dragleave.prevent="handleDragLeave"
        @drop="filesSelected"
      >
        <div class="text-black text-md pb-1">Drag and Drop files here</div>
        <div class="text-gray-400 text-xs pb-3">
          Files supported: jpg, png, jpeg, pdf
        </div>
        <label
          class="px-2 py-1 inline-block bg-black text-white text-sm rounded"
        >
          <input @change="filesSelected" type="file" id="" class="hidden" />
          Choose Files
        </label>
      </div>
      <ul class="mt-5 flex gap-2 flex-wrap">
        <li
          style="height: 200px; flex-grow: 1"
          class="relative"
          v-for="(url, i) in imageUrls"
          :key="i"
        >
          <img
            style="
              max-height: 100%;
              min-width: 100%;
              object-fit: cover;
              vertical-align: bottom;
            "
            :src="url"
          />
          <div class="close" @click="deselectFile(i)">
            <svg
              class="close-img"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#ff0000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="15" y1="9" x2="9" y2="15"></line>
              <line x1="9" y1="9" x2="15" y2="15"></line>
            </svg>
          </div>
        </li>
      </ul>
      <div
        class="close"
        @click="
          existingFiles.length ? (viewImages = true) : $emit('close-modal')
        "
      >
        <svg
          class="close-img"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="15" y1="9" x2="9" y2="15"></line>
          <line x1="9" y1="9" x2="15" y2="15"></line>
        </svg>
      </div>
    </div>
    <button
      class="bg-black p-4 mt-auto block rounded w-full text-white"
      @click="selectFiles"
    >
      Save
    </button>
  </div>
  <!-- / Image upload Modal -->
</template>
 



<script>
export default {
  name: "ImageModal",
  emits: ["close-modal", "addFiles", "removeExistingFile"],
  props: ["existingFiles", "index"],
  data() {
    return {
      viewImages: this.existingFiles.length ? true : false,
      currentImageIndex: 0,
      files: [],
    };
  },
  watch: {
    "existingFiles.length": {
      handler(val) {
        if (val === 0) {
          this.viewImages = false;
        }
      },
    },
  },
  methods: {
    selectFiles() {
      this.$emit("addFiles", this.files, this.index);
      if (!this.files.length && !this.existingFiles.length) {
        this.$emit("close-modal");
      } else {
        this.viewImages = true;
      }

      this.files = [];
    },
    handleDragOver() {
      this.$refs.dragDrop.style.background = "#eee";
    },
    handleDragLeave() {
      this.$refs.dragDrop.style.background = "";
    },

    filesSelected(e) {
      e.preventDefault();
      this.$refs.dragDrop.style.background = "";

      if (
        e.dataTransfer &&
        e.dataTransfer.files &&
        e.dataTransfer.files.length
      ) {
        this.files.push(...e.dataTransfer.files);
      }

      if (e.target && e.target.files && e.target.files.length) {
        this.files.push(...e.target.files);
        e.target.value = null;
      }
    },
    deselectFile(i) {
      this.files = this.files.filter((file, ind) => {
        if (ind !== i) return file;
      });
    },
  },
  computed: {
    imageUrls() {
      if (!this.files) return [];
      return this.files.map((file) => {
        return URL.createObjectURL(file);
      });
    },
    hasFiles() {
      if (this.existingFiles && this.existingFiles.length) return true;
      else return false;
    },
    backgroundImage() {
      if (this.existingFiles && this.existingFiles.length)
        return `linear-gradient(180deg, rgba(0,0,0,.5) 0%, rgba(255,255,255,0) 25%), url(${URL.createObjectURL(
          this.existingFiles[this.currentImageIndex]
        )}) `;
      else
        return "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 25%)";
    },
  },
  mounted() {
    if (!this.hasFiles) {
      this.showImageUploadModal = true;
    }
  },
};
</script>


<style scoped>
.modal-overlay {
  position: fixed;
  z-index: 10001;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal-content {
  padding: 0 30px 30px 30px;
  overflow-y: auto;
  height: 90%;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar {
  width: 16px;
}

.modal {
  z-index: 10002;
  text-align: center;
  background-color: white;
  height: 520px;
  width: 620px;
  padding: 60px 0 0 0;
  border-radius: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.close {
  margin: 2% 0 0 16px;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 0;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

.button {
  background-color: #d4b376;
  width: 150px;
  height: 40px;
  color: black;
  font-size: 14px;
  border-radius: 6px;
  margin-top: 50px;
}

.button:hover {
  background-color: #251a05;
  width: 150px;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 6px;
  margin-top: 50px;
}
</style>

 