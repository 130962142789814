<template>
  <div class="overflow-auto">
    <div class="flex items-center font-semibold text-black text-xl px-6 my-4">
      <svg
        @click="$router.go(-1)"
        class="flex cursor-pointer items-center mr-2"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000000"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M19 12H6M12 5l-7 7 7 7" />
      </svg>

      New Order Inquiry
    </div>

    <div
      class="
        px-6
        mt-4
        text-gray-500 text-sm
        xl:w-1/2
        lg:w-1/2
        md:w-full
        sm:w-full
      "
    >
      <p>
        If you would like to know about the availability of a product, or would
        like to place a new order, please fill out this form and our customer
        service team will get back to you as soon as possible.
      </p>
    </div>
    <div class="xl:w-1/2 lg:w-1/2 md:w-full sm:w-full">
      <div
        class="
          bg-white
          px-6
          pt-4
          text-sm text-gray-500
          uppercase
          flex
          items-center
        "
      >
        <span class="w-1/2"> email address* </span>
        <input
          v-model="newOrder.customerEmail"
          class="
            px-4
            w-full
            py-2
            border
            text-gray-800
            border-gray-300
            rounded
            outline-none
          "
          type="email"
          placeholder="your@email.com"
        />
      </div>
      <div
        class="
          bg-white
          px-6
          pt-4
          text-sm text-gray-500
          uppercase
          flex
          items-center
        "
      >
        <span class="w-1/2"> subject* </span>
        <input
          v-model="newOrder.subject"
          class="
            px-4
            w-full
            py-2
            border
            text-gray-800
            border-gray-300
            rounded
            outline-none
          "
          type="text"
          placeholder="What may we help you with?"
        />
      </div>
      <div
        class="
          bg-white
          px-6
          pt-4
          text-sm text-gray-500
          uppercase
          flex
          items-center
        "
      >
        <span class="w-1/2"> description* </span>
        <textarea
          v-model="newOrder.description"
          class="
            px-4
            w-full
            py-2
            border
            text-gray-800
            border-gray-300
            rounded
            outline-none
          "
          type="text"
          placeholder="A short description of what you are looking for..."
        />
      </div>
    </div>

    <div class="xl:w-1/2 lg:w-1/2 md:w-full sm:w-full">
      <div
        class="
          bg-white
          px-6
          pt-4
          text-sm text-gray-500
          uppercase
          flex
          items-center
        "
      >
        <span class="w-1/2"> parent equipment </span>
      </div>

      <div
        class="
          bg-white
          px-6
          pt-4
          text-sm text-gray-500
          uppercase
          flex
          items-center
        "
      >
        <span class="w-1/2"> Model No* </span>

        <input
          v-model="newOrder.modelNumber"
          class="
            px-4
            w-full
            py-2
            border
            text-gray-800
            border-gray-300
            rounded
            outline-none
          "
          type="text"
          placeholder="Enter the Model Number here"
        />
      </div>

      <div
        class="
          bg-white
          px-6
          pt-4
          text-sm text-gray-500
          uppercase
          flex
          items-center
        "
      >
        <span class="w-1/2"> Serial No* </span>

        <input
          v-model="newOrder.serialNumber"
          class="
            px-4
            w-full
            py-2
            border
            text-gray-800
            border-gray-300
            rounded
            outline-none
          "
          type="text"
          placeholder="Enter the Serial Number here"
        />
      </div>
    </div>

    <div
      class="
        bg-white
        px-6
        pt-4
        text-sm text-gray-500
        uppercase
        flex
        items-center
      "
    >
      <div class="mr-32">rfq*</div>
    </div>

    <div>
      <table
        class="
          border-collapse
          w-full
          xl:w-2/3
          lg:w-2/3
          mt-2
          sm:mx-0
          md:mx-6
          lg:mx-6
          xl:mx-6
        "
      >
        <thead>
          <tr>
            <th
              class="
                pl-2
                py-1
                font-bold
                text-sm
                uppercase
                text-left text-gray-600
                hidden
                lg:table-cell
              "
            >
              #
            </th>
            <th
              class="
                pl-2
                py-1
                font-bold
                text-sm
                uppercase
                text-gray-600
                hidden
                lg:table-cell
              "
            >
              Part No
            </th>
            <th
              class="
                pl-2
                py-1
                font-bold
                text-sm
                uppercase
                text-left text-gray-600
                hidden
                lg:table-cell
              "
            >
              Description
            </th>
            <th
              class="
                pl-2
                py-1
                font-bold
                text-sm
                uppercase
                text-gray-600
                hidden
                lg:table-cell
              "
            ></th>
            <th
              class="
                pl-2
                py-1
                font-bold
                text-sm
                uppercase
                text-gray-600
                hidden
                lg:table-cell
              "
            >
              Unit
            </th>
            <th
              class="
                pl-2
                py-1
                font-bold
                text-sm
                uppercase
                text-gray-600
                hidden
                lg:table-cell
              "
            >
              Qty
            </th>
            <th
              class="
                pl-2
                py-1
                font-bold
                text-sm
                uppercase
                text-gray-600
                hidden
                lg:table-cell
              "
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, i) in newOrder.items"
            :key="i"
            class="
              bg-white
              text-sm
              lg:hover:bg-gray-100
              flex
              lg:table-row
              flex-row
              lg:flex-row
              flex-wrap
              lg:flex-no-wrap
              mb-10
              lg:mb-0
              xl:border-0
              lg:border-0
              border-8 border-t-0 border-l-0 border-r-0 border-gray-200
              sm:border-8
              sm:border-t-0
              sm:border-l-0
              sm:border-r-0
              sm:border-gray-200
            "
          >
            <ImageModal
              @addFiles="imageModalFilesAdded"
              @removeExistingFile="removeExistingFile"
              v-if="item.showImagesModal"
              @close-modal="item.showImagesModal = false"
              :existingFiles="item.files"
              :index="i"
            ></ImageModal>
            <td
              class="
                w-full
                lg:w-auto
                p-3
                pt-6
                sm:p-3 sm:pt-6
                lg:p-2
                text-gray-800
                lg:text-left
                xl:text-left
                text-center
                lg:border-0
                xl:border-0
                border border-b
                block
                lg:table-cell
                relative
                lg:static
              "
            >
              <span
                class="
                  lg:hidden
                  absolute
                  top-0
                  left-0
                  bg-gray-200
                  px-2
                  py-1
                  text-xs
                  font-bold
                  uppercase
                  mx-6
                "
                >#</span
              >
              {{ item.id }}
            </td>
            <td
              class="
                w-full
                lg:w-auto
                p-4
                pt-6
                sm:p-4 sm:pt-6
                lg:p-2
                text-sm text-gray-800 text-center
                lg:border-0
                xl:border-0
                border border-b
                block
                lg:table-cell
                relative
                lg:static
              "
            >
              <span
                class="
                  lg:hidden
                  absolute
                  top-0
                  left-0
                  bg-gray-200
                  px-2
                  py-1
                  text-xs
                  font-bold
                  uppercase
                  mx-6
                "
                >Part/Model</span
              >
              {{ item.partNo }}
            </td>
            <td
              class="
                w-full
                lg:w-auto
                p-4
                pt-8
                sm:p-4 sm:pt-6
                lg:p-2
                text-sm text-gray-800
                lg:text-left
                xl:text-left
                text-center
                lg:border-0
                xl:border-0
                border border-b
                block
                lg:table-cell
                relative
                lg:static
              "
            >
              <span
                class="
                  lg:hidden
                  absolute
                  top-0
                  left-0
                  bg-gray-200
                  px-2
                  py-1
                  text-xs
                  font-bold
                  uppercase
                  mx-6
                "
                >Description</span
              >
              {{ item.description }}
            </td>
            <td
              class="
                w-full
                lg:w-auto
                p-4
                pt-6
                sm:p-4 sm:pt-6
                lg:p-2
                text-gray-800 text-center
                lg:border-0
                xl:border-0
                border border-b
                block
                lg:table-cell
                relative
                lg:static
              "
            >
              <span
                class="
                  lg:hidden
                  absolute
                  top-0
                  left-0
                  bg-gray-200
                  px-2
                  py-1
                  text-xs
                  font-bold
                  uppercase
                  mx-6
                "
                >Image</span
              >
              <svg
                @click="item.showImagesModal = true"
                class="mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" />
                <circle cx="8.5" cy="8.5" r="1.5" />
                <path d="M20.4 14.5L16 10 4 20" />
              </svg>
            </td>
            <td
              class="
                w-full
                lg:w-auto
                p-4
                pt-6
                sm:p-4 sm:pt-6
                lg:p-2
                text-gray-800 text-center
                lg:border-0
                xl:border-0
                border border-b
                block
                lg:table-cell
                relative
                lg:static
              "
            >
              <span
                class="
                  lg:hidden
                  absolute
                  top-0
                  left-0
                  bg-gray-200
                  px-2
                  py-1
                  text-xs
                  font-bold
                  uppercase
                  mx-6
                "
                >Unit</span
              >
              {{ item.unit }}
            </td>
            <td
              class="
                w-full
                lg:w-auto
                p-4
                pt-6
                sm:p-4 sm:pt-6
                lg:p-2
                text-gray-800 text-center
                lg:border-0
                xl:border-0
                border border-b
                block
                lg:table-cell
                relative
                lg:static
              "
            >
              <span
                class="
                  lg:hidden
                  absolute
                  top-0
                  left-0
                  bg-gray-200
                  px-2
                  py-1
                  text-xs
                  font-bold
                  uppercase
                  mx-6
                "
                >Qty</span
              >
              {{ item.qty }}
            </td>
            <td>
              <button
                @click="
                  newOrder.items = newOrder.items.filter(
                    (itm) => itm._id !== item._id
                  )
                "
                class="p-1 px-2 text-red-400 bg-red-100 rounded"
              >
                &#x2715;
              </button>
            </td>
          </tr>

          <tr
            class="
              bg-white
              text-sm
              lg:hover:bg-gray-100
              flex
              lg:table-row
              flex-row
              lg:flex-row
              flex-wrap
              lg:flex-no-wrap
              mb-10
              lg:mb-0
              xl:border-0
              lg:border-0
              border-8 border-t-0 border-l-0 border-r-0 border-gray-200
              sm:border-8
              sm:border-t-0
              sm:border-l-0
              sm:border-r-0
              sm:border-gray-200
            "
          >
            <td
              class="
                w-full
                lg:w-auto
                p-3
                pt-6
                sm:p-3 sm:pt-6
                lg:p-2
                text-gray-800
                lg:text-left
                xl:text-left
                text-center
                lg:border-0
                xl:border-0
                border border-b
                block
                lg:table-cell
                relative
                lg:static
              "
            >
              <span
                class="
                  lg:hidden
                  absolute
                  top-0
                  left-0
                  bg-gray-200
                  px-2
                  py-1
                  text-xs
                  font-bold
                  uppercase
                  mx-6
                "
                >#</span
              >
              <input
                disabled
                :value="nextId"
                type="text"
                placeholder="001"
                class="
                  w-8
                  text-black text-sm
                  rounded
                  border
                  py-0.5
                  border-gray-400
                  pl-1
                "
              />
            </td>
            <td
              class="
                w-full
                lg:w-auto
                p-4
                pt-6
                sm:p-4 sm:pt-6
                lg:p-2
                text-gray-800 text-center
                lg:border-0
                xl:border-0
                border border-b
                block
                lg:table-cell
                relative
                lg:static
              "
            >
              <span
                class="
                  lg:hidden
                  absolute
                  top-0
                  left-0
                  bg-gray-200
                  px-2
                  py-1
                  text-xs
                  font-bold
                  uppercase
                  mx-6
                "
                >Part/Model</span
              >
              <input
                v-model="newItem.partNo"
                type="text"
                placeholder="P001"
                class="
                  w-16
                  text-black text-sm
                  rounded
                  border
                  py-0.5
                  border-gray-400
                  pl-1
                "
              />
            </td>
            <td
              class="
                w-full
                lg:w-auto
                p-4
                pt-8
                sm:p-4 sm:pt-6
                lg:p-2
                text-gray-800
                lg:text-left
                xl:text-left
                text-center
                lg:border-0
                xl:border-0
                border border-b
                block
                lg:table-cell
                relative
                lg:static
              "
            >
              <span
                class="
                  lg:hidden
                  absolute
                  top-0
                  left-0
                  bg-gray-200
                  px-2
                  py-1
                  text-xs
                  font-bold
                  uppercase
                  mx-6
                "
                >Description</span
              >
              <input
                v-model="newItem.description"
                type="text"
                placeholder="Item Description"
                class="
                  w-full
                  text-black text-sm
                  rounded
                  border
                  py-0.5
                  border-gray-400
                  pl-1
                "
              />
            </td>

            <ImageUploadModal
              v-show="newItem.showImageUploadModal"
              @filesSelected="setItemFiles"
              @close-modal="newItem.showImageUploadModal = false"
            />

            <td
              class="
                w-full
                lg:w-auto
                p-4
                pt-6
                sm:p-4 sm:pt-6
                lg:p-2
                text-gray-800 text-center
                lg:border-0
                xl:border-0
                border border-b
                block
                lg:table-cell
                relative
                lg:static
              "
            >
              <span
                class="
                  lg:hidden
                  absolute
                  top-0
                  left-0
                  bg-gray-200
                  px-2
                  py-1
                  text-xs
                  font-bold
                  uppercase
                  mx-6
                "
                >Image</span
              >
              <svg
                @click="newItem.showImageUploadModal = true"
                class="mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" />
                <circle cx="8.5" cy="8.5" r="1.5" />
                <path d="M20.4 14.5L16 10 4 20" />
              </svg>
            </td>
            <td
              class="
                w-full
                lg:w-auto
                p-4
                pt-6
                sm:p-4 sm:pt-6
                lg:p-2
                text-gray-800 text-center
                lg:border-0
                xl:border-0
                border border-b
                block
                lg:table-cell
                relative
                lg:static
              "
            >
              <span
                class="
                  lg:hidden
                  absolute
                  top-0
                  left-0
                  bg-gray-200
                  px-2
                  py-1
                  text-xs
                  font-bold
                  uppercase
                  mx-6
                "
                >Unit</span
              >
              <input
                v-model="newItem.unit"
                type="text"
                placeholder="NOS"
                class="
                  w-16
                  text-black text-sm
                  rounded
                  border
                  py-0.5
                  border-gray-400
                  pl-1
                "
              />
            </td>
            <td
              class="
                w-full
                lg:w-auto
                p-4
                pt-6
                sm:p-4 sm:pt-6
                lg:p-2
                text-gray-800 text-center
                lg:border-0
                xl:border-0
                border border-b
                block
                lg:table-cell
                relative
                lg:static
              "
            >
              <span
                class="
                  lg:hidden
                  absolute
                  top-0
                  left-0
                  bg-gray-200
                  px-2
                  py-1
                  text-xs
                  font-bold
                  uppercase
                  mx-6
                "
                >Qty</span
              >
              <input
                v-model="newItem.qty"
                type="number"
                placeholder="4000"
                class="
                  w-16
                  text-black text-sm
                  rounded
                  border
                  py-0.5
                  pl-1
                  border-gray-400
                "
              />
            </td>

            <td>
              <button
                :disabled="!canAddNewItem"
                :class="{ 'cursor-not-allowed opacity-50': !canAddNewItem }"
                @click.prevent="addNewItem"
                class="button text-sm text-center rounded px-4 py-0.5"
              >
                Add
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="
        bg-white
        px-6
        pt-4
        text-sm text-gray-500
        uppercase
        xl:flex
        lg:flex
        md:flex
        items-center
        mb-10
      "
    >
      <div class="mr-20 mb-2">attachments<br />(optional)</div>

      <div
        ref="dragDrop"
        class="
          items-center
          text-center
          border
          rounded
          border-gray-400
          hover:border-black
          border-dashed
          p-10
          px-20
        "
        @dragover.prevent="handleDragOver"
        @dragleave.prevent="handleDragLeave"
        @drop="filesSelected"
      >
        <div class="text-black text-md pb-1">Drag and Drop files here</div>

        <div class="text-gray-400 text-xs pb-3">
          Files supported: jpg, png, jpeg, pdf
        </div>

        <label
          class="px-2 py-1 inline-block bg-black text-white text-sm rounded"
        >
          <input @change="filesSelected" type="file" id="" class="hidden" />
          Choose Files
        </label>
      </div>
      <div>
        <div
          class="
            border border-gray-200
            flex
            justify-between
            ml-2
            bg-gray-100
            p-3
          "
          v-for="(file, i) in files"
          :key="i"
        >
          {{ file.name }}
          <button
            @click="files = files.filter((f, ind) => ind != i)"
            class="text-red-500 px-3 font-bold ml-2"
          >
            X
          </button>
        </div>
      </div>
    </div>

    <div class="px-6 flex items-center mb-20">
      <button
        class="
          bg-gray-500
          hover:bg-black
          text-white text-md text-center
          mr-3
          rounded
          px-8
          py-1
        "
      >
        Cancel</button
      ><button
        @click.prevent="
          newOrderInquiry({ data: this.newOrder, files: this.files }),
            resetForm()
        "
        class="button text-md text-center rounded px-8 py-1"
      >
        Submit
      </button>
    </div>
  </div>
</template>



<script>
import ImageUploadModal from "../modules/modals/ImageUploadModal";
import ImageModal from "@/components/modules/modals/ImageModal";
// import pdfsvg from "../base/pdfsvg";
import { mapActions } from "vuex";
export default {
  name: "NewOrderInquiry",
  data() {
    return {
      newOrder: {
        customerEmail: this.$store.state.auth.currentUser.contactEmail || "",
        subject: "",
        description: "",
        items: [],
        serialNumber: "",
        modelNumber: "",
      },
      files: [],
      newItem: {
        showImageUploadModal: false,
        files: [],
        partNo: "-",
        description: "",
        unit: "-",
        qty: "",
      },
      hover: false,
    };
  },
  methods: {
    ...mapActions(["newOrderInquiry"]),
    removeExistingFile(imageIndex, itemIndex) {
      this.newOrder.items[itemIndex].files.splice(imageIndex, 1);
    },
    imageModalFilesAdded(files, index) {
      this.newOrder.items[index].files.push(...files);
    },
    setItemFiles(newFiles) {
      this.newItem.files = [...newFiles];
    },
    handleDragOver() {
      this.$refs.dragDrop.style.background = "#eee";
    },
    handleDragLeave() {
      this.$refs.dragDrop.style.background = "";
    },

    filesSelected(e) {
      e.preventDefault();
      this.$refs.dragDrop.style.background = "";

      if (
        e.dataTransfer &&
        e.dataTransfer.files &&
        e.dataTransfer.files.length
      ) {
        this.files.push(...e.dataTransfer.files);
      }

      if (e.target && e.target.files && e.target.files.length) {
        this.files.push(...e.target.files);
        e.target.value = null;
      }
    },
    addNewItem() {
      this.newOrder.items.push({
        partNo: this.newItem.partNo,
        description: this.newItem.description,
        files: this.newItem.files,
        unit: this.newItem.unit,
        qty: this.newItem.qty,
        id: this.nextId,
      });

      this.newItem = {
        partNo: "-",
        description: "",
        files: [],
        unit: "-",
        qty: "",
      };
    },
    resetForm() {
      this.newOrder = {
        customerEmail: this.$store.state.auth.currentUser.contactEmail || "",
        subject: "",
        description: "",
        items: [],
        serialNumber: "",
        modelNumber: "",
      };
    },
  },
  computed: {
    nextId() {
      this.newOrder.items.length;

      let newId = this.newOrder.items.length + 1;

      if (newId < 10) {
        newId = `0${newId}`;
      }

      return newId;
    },
    canAddNewItem() {
      return (
        this.newItem &&
        this.newItem.partNo &&
        this.newItem.description &&
        this.newItem.unit &&
        this.newItem.qty
      );
    },
  },
  components: { ImageUploadModal, ImageModal },
};
</script>




<style scoped>
.button {
  background: #d4b376;
  color: #000;
}

.button:hover {
  background: #000;
  color: #fff;
}
.inq-table {
  th {
    position: sticky;
    z-index: 48;
  }
}

ul li.liborder:hover,
ul li.liborder:active {
  border-bottom: solid 4px;
  border-color: #000;
}
</style>